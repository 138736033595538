<template>
    <div class="message-text">
        <div class="c-black" v-if="isFaq" v-html="$translate('ACTION_FAQ_OPEN_CHAT_NORMAL_BODY')" />
        <!-- <div class="c-black" v-else v-html="$translate('ACTION_OPEN_CHAT_NORMAL_BODY')" /> -->
        <div v-else class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>환영합니다:)</span>
            </div>
            <div class="noti-body">
                잠시만 기다려주세요. <br />
                매니저가 곧 연락드릴 예정입니다!
            </div>
            <div class="noti-blue-box">
                <div>매니저 응대시간</div>
                <div class="noti-time">평일 오전 9시 ~ 오후 6시</div>
            </div>
            <!-- <div class="noti-body">
                <p class="m-b-16">
                    기다리시는 동안 매칭 선호 사항을 먼저 작성해주세요! 이상형 파악에 큰 도움이 됩니다.
                </p>
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionOpenChatNormal',
    props: ['message'],
    data: () => ({
        stats: [],
    }),
    computed: {
        actionOpenChatNonPro() {
            return this.$translate('ACTION_OPEN_CHAT_NON_PRO').replace(/%s/, this.chat.user.name)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isFaq() {
            const chatUser = this.chat.user

            return chatUser.id === 2
        },
    },
    methods: {
        onClickMatchPrefer() {
            this.$stackRouter.push({
                name: 'EditStylePage',
            })
        },
    },
}
</script>
<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f4fcff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
